<template>
  <div class="page page-error">
    <!-- <img class="error-404" src="~@/assets/images/404.png" alt="错误提示"> -->
    <div class="content-area">
      <svg
        t="1566202252208"
        class="icon-ss"
        viewBox="0 0 2525 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="919"
        width="80"
        height="40"
      >
        <path
          d="M652.666326 1020.930058h-178.407992v-249.524912H0v-106.506532L415.888497 45.029505h236.777829v580.32143h182.351163v146.054211h-182.354574v249.521501z m-182.83212-395.930461l10.635644-459.263371-287.653595 459.263371h277.021362zM2341.134602 1020.930058h-178.407992v-249.524912H1688.468276v-106.506532L2104.356773 45.029505h236.777829v580.32143h182.351163v146.054211h-182.354574v249.521501z m-182.83212-395.930461l10.635644-459.263371-287.653595 459.263371h277.021362zM1316.190015 990.537629c-111.807299 0-217.368971-35.713662-284.924758-136.919428-67.552375-101.205765-82.605326-206.975511-82.605325-337.093311 0-130.114389 28.165015-265.526133 94.915794-352.20766 66.750779-86.681527 170.293112-121.808489 272.614289-121.808489 105.851611 0 223.577077 43.657991 290.76447 135.65734 67.190804 91.995938 81.101054 212.781113 81.101054 338.358809 0 125.577696-0.808418 202.537739-68.09132 316.330268-67.282903 113.792529-184.083975 157.685882-303.774204 157.685882z m-1.033547-148.861503c51.094073 0 107.891417-29.618121 147.520961-112.796503 16.110375-36.914351 31.354344-136.100776 31.354345-207.674776 0-83.195437-6.242216-172.199887-36.675578-227.499781-29.379348-53.376064-64.57112-94.496236-142.199728-94.496236-78.535947 6.961947-117.25133 40.277643-139.354915 94.496236-22.103585 54.222003-38.360635 127.245698-38.360635 229.652152 0 89.52634 13.610078 151.576697 38.360635 205.522405 29.607888 64.543832 77.840093 112.796503 139.354915 112.796503z"
          fill="#48b4ec"
          p-id="920"
        />
        <path
          d="M764.920473 813.28257l38.681273-7.245064-107.656055-32.275327-18.573151 3.786263z"
          fill="#FFF6E5"
          p-id="921"
        />
        <path
          d="M832.524014 11.389759L798.979777 3.049749l-33.858053 8.34001v802.534088l29.631766 7.497481 37.770524-7.497481z"
          fill="#FFEBC8"
          p-id="922"
        />
        <path
          d="M794.791011 20.019708l-29.669287-9.878392V813.811282l29.669287 7.640746z"
          fill="#FFDEA6"
          p-id="923"
        />
        <path
          d="M832.554713 11.372704l-37.69207 8.681114-29.792084-9.857926L799.071875 2.810976z"
          fill="#F7CB81"
          p-id="924"
        />
        <path
          d="M665.638538 38.504172l-21.107559 5.839712v284.365346l258.864359 61.282871 19.439557-5.195025V98.96498z"
          fill="#FFEBC8"
          p-id="925"
        />
        <path
          d="M644.530979 47.959594l257.929732 60.085593v284.085641L644.530979 329.510826z"
          fill="#FFDEA6"
          p-id="926"
        />
        <path
          d="M828.123763 278.959109c33.336163-49.142954-7.483837-96.437121-13.429292-104.101744-25.258803-30.310564-55.323772-39.32255-90.194906-27.035958l-0.29335 0.177374 103.917548 130.960328z m-17.850009 13.558912L707.147569 162.574185c-6.815272 8.271789-11.04497 18.003506-12.689095 29.198563-2.749304 36.539136 15.267846 75.302274 50.705214 95.509316 23.931906 13.644188 48.743862 11.918198 65.110066 5.239368zM718.16184 120.689939c12.695917-2.790236 21.755658-4.185355 27.175812-4.185355 20.016024 0 32.183229 4.185355 32.183228 4.185355 55.70922 16.987014 84.887316 77.556976 89.495641 102.16768 4.604913 24.607293 0 86.217625-56.254987 100.949937-61.808172 8.988109-110.777163-33.564703-126.543023-72.962296-22.625475-65.219219-11.314443-108.604326 33.939918-130.155321z"
          fill="#F7CB81"
          p-id="927"
        />
        <path
          d="M835.795208 925.550361l219.992066-41.82967-123.285472-40.963263-193.106194 49.303273z"
          fill="#FFF6E5"
          p-id="928"
        />
        <path
          d="M1195.486705 1015.966984l219.193882-43.681868-142.489668-18.822157-167.236813 37.521517z"
          fill="#F7EBDA"
          p-id="929"
        />
        <path
          d="M833.639426 893.084015l28.274169 6.750462v33.472605l-28.27758-6.242216z"
          fill="#F7CB81"
          p-id="930"
        />
        <path
          d="M922.207262 596.176249v276.49265l27.032548 7.6578 36.754031-7.6578-3.12793-276.49265z"
          fill="#FFDEA6"
          p-id="931"
        />
        <path
          d="M950.266535 596.459366v282.946351l-88.404106 20.309374v33.663623l194.003299-48.842783v-27.82391l-70.008329 15.643062-3.042654-275.895717z"
          fill="#FFEBC8"
          p-id="932"
        />
        <path
          d="M921.06115 872.106076l-87.227294 20.517448v34.482274l28.110438 6.399125v-33.701145l87.292104-19.354281zM985.809645 859.075876l39.660243-9.823815 30.331031 7.545236-69.807078 15.632829z"
          fill="#FFDEA6"
          p-id="933"
        />
        <path
          d="M1282.267152 685.109067v276.49265l27.035959 7.6578 36.754032-7.6578-3.131341-276.49265z"
          fill="#FFDEA6"
          p-id="934"
        />
        <path
          d="M1310.329836 685.392184v282.946351l-88.407516 20.309374v33.663623l194.003299-48.846194v-27.82391l-70.008329 15.646472-3.042654-275.895716z"
          fill="#FFEBC8"
          p-id="935"
        />
        <path
          d="M1281.121041 961.038894l-87.227295 20.517448v34.478863l28.110439 6.402535v-33.704555l87.292104-19.354281zM1345.869535 948.008694l39.660244-9.823815 30.334441 7.545236-69.807077 15.632829z"
          fill="#FFDEA6"
          p-id="936"
        />
        <path
          d="M872.726614 530.455606v72.263032l461.501018 107.587834 37.316854-9.779472v-71.154441l-466.733564-108.733946z"
          fill="#FFEBC8"
          p-id="937"
        />
        <path
          d="M1335.189547 637.426041l-0.951682 72.81221-461.405509-107.079588-0.109153-72.68259z"
          fill="#FFDEA6"
          p-id="938"
        />
        <path
          d="M910.592647 611.877299l40.23671-63.336321-40.23671-9.264404-37.709125 63.899144zM991.601602 557.96229l-40.628981 63.315854 38.026353 8.834612 40.748367-63.298799zM1107.819386 584.861806l-40.540294 63.486408-37.72618-8.882367 39.131531-63.674015zM1149.052122 594.409327L1108.112736 657.755881l38.155972 8.875545 40.100268-63.55804zM1263.714468 620.916573l-39.789864 63.725181-38.602819-8.971054 40.959853-63.428419zM1304.595866 630.409517l-39.602256 63.752469 37.361198 8.786857 32.650542-52.860995v-12.67204z"
          fill="#F7CB81"
          p-id="939"
        />
        <path
          d="M1334.926896 620.844941l-30.276453 9.653263 30.375374 6.924426 36.515258-8.04666zM1298.084177 612.245692l-34.417464 8.677703-37.33391-8.677703 31.258835-9.41449zM1217.723321 593.546332l-31.388455 9.523643-37.211112-8.664059 32.711941-9.233704zM1137.662636 574.884494l-29.914882 9.973901-39.039434-9.066563 31.483964-9.632796zM1060.992532 557.010607l-31.245191 9.834049-38.135505-8.868722 30.98254-9.915914zM983.565176 539.00369l-32.800628 9.636208-40.13438-9.281459 33.128089-9.585042z"
          fill="#FFE0AB"
          p-id="940"
        />
        <path
          d="M872.726614 663.854833v72.25962l461.501018 107.587835 37.316854-9.779472v-71.154441l-466.733564-108.733946z"
          fill="#FFEBC8"
          p-id="941"
        />
        <path
          d="M1335.189547 770.821857l-0.951682 72.815621-461.405509-107.083-0.109153-72.682589z"
          fill="#FFDEA6"
          p-id="942"
        />
        <path
          d="M910.592647 745.276526l40.23671-63.339732-40.23671-9.260993-37.709125 63.895733zM991.601602 691.358105l-40.628981 63.319266 38.026353 8.834612 40.748367-63.302211zM1107.819386 718.261033l-40.540294 63.486407-37.72618-8.882366 39.131531-63.677426zM1149.052122 727.805143l-40.939386 63.349965 38.155972 8.872133 40.100268-63.554628zM1263.714468 754.312389l-39.789864 63.728592-38.602819-8.971054 40.959853-63.43183zM1304.595866 763.805333l-39.602256 63.75588 37.361198 8.783446 32.650542-52.857585v-12.67545z"
          fill="#F7CB81"
          p-id="943"
        />
        <path
          d="M1334.926896 754.240757l-30.276453 9.653263 30.375374 6.924426 36.515258-8.043249zM1298.084177 745.644919l-34.417464 8.674292-37.33391-8.674292 31.258835-9.417901zM1217.723321 726.945559l-31.388455 9.523643-37.211112-8.664059 32.711941-9.237115zM1137.662636 708.28031l-29.914882 9.973901-39.039434-9.066563 31.483964-9.632797zM1060.992532 690.406423l-31.245191 9.83746-38.135505-8.868722 30.98254-9.919325zM983.565176 672.399506l-32.800628 9.636208-40.13438-9.278048 33.128089-9.588453z"
          fill="#FFE0AB"
          p-id="944"
        />
        <path
          d="M1501.44739 872.703009l321.347918-42.607388-257.148602-23.785231-160.704658 42.818873z"
          fill="#F7EBDA"
          p-id="945"
        />
        <path
          d="M1501.116518 849.275938v22.724396l212.276279 48.634708 210.942558-48.634708v-22.724396l-224.907385-45.554533z"
          fill="#FFEBC8"
          p-id="946"
        />
        <path
          d="M1501.116518 849.258883l212.665138 49.743299v21.626038l-212.665138-48.60742z"
          fill="#F7CB81"
          p-id="947"
        />
        <path
          d="M1713.686147 920.641864l210.563932-48.757506v-22.570898l-210.563932 49.501114z"
          fill="#FFDEA6"
          p-id="948"
        />
        <path
          d="M1672.552331 519.315127l-9.605509 41.232736c14.838055 3.786262 31.08146 5.679393 48.730218 5.679393 17.645346 0 35.983135-1.893131 55.006544-5.679393L1754.137753 519.315127c-15.21327 3.428102-29.369115 5.140448-42.460713 5.140448a152.746686 152.746686 0 0 1-39.124709-5.140448zM1651.090023 602.964233l-10.465093 40.182134c20.016024 8.08077 42.668787 12.382101 67.954879 12.90058 25.286092 0.518479 51.339669-2.783414 78.164143-9.898859l-12.627696-43.183855c-24.286655 5.853357-46.134411 8.780035-65.536447 8.780035-19.405447 0-38.565298-2.926678-57.486375-8.780035zM1631.094465 683.826514L1621.093275 722.920524c28.550464 13.173464 58.441469 20.03649 89.676426 20.59249 31.231547 0.556001 63.609205-5.201847 97.122742-17.26672l-7.050634-42.41978c-35.106496 10.233141-66.010582 15.349712-92.708847 15.349711-26.694854 0-52.376627-5.116571-77.038497-15.349711zM1611.641264 761.895147L1601.56162 803.762338c30.26622 19.466845 65.055489 29.696575 104.367806 30.68919 39.312317 0.992615 80.422256-7.316696 123.333227-24.934754l-8.316133-43.750089c-39.704587 13.934127-76.837245 20.582258-111.408207 19.944392-34.570962-0.637866-67.204448-8.575372-97.897049-23.809108z"
          fill="#F7CB81"
          p-id="949"
        />
        <path
          d="M1693.636012 460.757683a19.531655 3.411047 0 1 0 39.063311 0 19.531655 3.411047 0 1 0-39.063311 0Z"
          fill="#F7CB81"
          p-id="950"
        />
      </svg>
      <p class="error-info">抱歉，页面无法访问...</p>
      <!-- <router-link to="/">返回首页</router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageError'
}
</script>

<style lang="less" rel="stylesheet/less">
.page-error {
  padding: 20px;

  .content-area {
    text-align: center;
    padding: 20px;
  }

  .error-404 {
    width: 100%;
    padding: 80px 0 0;
  }

  .error-info {
    text-align: center;
    font-size: 14px;
    padding: 10px 0 80px;
  }

  .icon-icon-404 {
    color: #48b4ec;
    opacity: 0.5;
  }
}
</style>
